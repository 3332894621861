<template>
  <v-container fill-height fluid>
    <v-layout row wrap align-center>
      <v-flex>
        <v-row class="score-count"> Poägg: {{ score }} </v-row>
        <canvas id="canvas" :style="{ margin: -borderMargin + 'px' }" @onload="gameLoop()" :width="canvasSize"
          :height="canvasSize"></canvas>
        <div class="drunk_container">
          <div class="multiplier">x1</div>
          <div class="drunkbar"></div>
        </div>
        <button class="restart hidden" @click="newGame()">Omstaaart</button>
        <div class="leaderboard_container" v-if="show_name_input">
          <div class="leaderboard_message">Skicka till tupplista</div>
          <input id="name_input" type="text" maxlength="11" />
          <button class="submit_name" @click="saveScore()">Skicka</button>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
/* eslint-disable */
import axios from "axios";
const cellSize = 36.0;
const cells = 12;
const border = 4;
const borderMargin = 100;
const innerSize = cells * cellSize + 2 * border;

let drunkDecay = 2000;
let drunkLevel = 1;
let drinkTimer = 0;

const base_move_interval = 20;
let moveInterval = base_move_interval;
let moveTiming = 0;
let maxLength = 1;

let initialized = false;
let alive = true;
let deathAnimationTime = 0;
let movedThisTick = false;

let egg_pos = {
  x: 0,
  y: 0,
};
let beer_pos = {
  x: 0,
  y: 0,
};

let joints = [
  {
    x: 0,
    y: 0,
  },
];

let dir = {
  x: 0,
  y: 0,
};

function getDirName(dir) {
  if (dir.x !== 0) {
    return dir.x > 0 ? "right" : "left";
  }
  if (dir.y !== 0) {
    return dir.y > 0 ? "down" : "up";
  }
}

function getBendDir(prevDir, nextDir) {
  // up: down left
  // right: down right
  // down: up right
  // left: up left

  if (prevDir == "down") {
    return nextDir == "right" ? "right" : "up";
  } else if (prevDir == "right") {
    return nextDir == "down" ? "left" : "up";
  } else if (prevDir == "up") {
    return nextDir == "left" ? "left" : "down";
  } else if (prevDir == "left") {
    return nextDir == "down" ? "down" : "right";
  }
}

export default {
  name: "GameScreen",
  data: function () {
    return {
      sprites: {},
      ctx: null,
      score: 0,
      canvasSize: innerSize + 2 * borderMargin,
      borderMargin: borderMargin,
      show_name_input: false,
    };
  },
  methods: {
    async saveScore() {
      const name_input = document.getElementById("name_input");
      // Kräv namn
      if (name_input.value.length == 0) {
        return;
      }

      // Göm namnfältet
      this.show_name_input = false;

      // Kryptera och skicka till backend
      // Kryptering görs för att försvåra fuffens med tupplistan
      const score_json = JSON.stringify({
        name: name_input.value,
        score: this.score,
      });
      const baseURL =
        process.env.NODE_ENV === "production" ? "" : "http://localhost:3000";
      const salto = this.salt();
      this.encrypt(
        score_json,
        "fnakare och 1337",
        salto,
        async (payload) => {
          try {
            await axios.post(`${baseURL}/api/fnake/score`, {
              salt: salto,
              payload,
            });
          } catch (err) {
            console.warn(err);
          }
        }
      );
    },
    rotateCanvas(x, y, dir) {
      // Roterar canvas runt en punkt (x, y)
      const dirs = {
        left: 0,
        up:  (1 / 2) * Math.PI,
        right: Math.PI,
        down: (3 / 2) * Math.PI,
      };
      this.ctx.translate(
        (x + 0.5) * cellSize + borderMargin + border,
        (y + 0.5) * cellSize + borderMargin + border
      );
      this.ctx.rotate(dirs[dir]);
      this.ctx.translate(
        -(x + 0.5) * cellSize - borderMargin - border,
        -(y + 0.5) * cellSize - borderMargin - border
      );
    },
    drawPart(x, y, part, dirName) {
      // Ritar ett segment av Fnake
      this.ctx.save();
      this.rotateCanvas(x, y, dirName);
      let offsetY = 0;
      if (part == "head") {
        // Huvudet förskjuts uppåt för att passa ihop med kroppen
        offsetY = -7;
      }
      this.ctx.drawImage(
        this.sprites[part],
        x * cellSize + borderMargin + border,
        y * cellSize + offsetY + borderMargin + border,
        cellSize,
        cellSize
      );
      this.ctx.restore();
    },
    draw() {
      this.ctx.clearRect(0, 0, this.canvasSize, this.canvasSize);

      // Draw border
      this.ctx.fillStyle = "white";
      this.ctx.fillRect(borderMargin, borderMargin, innerSize, border);
      this.ctx.fillRect(borderMargin, borderMargin, border, innerSize);
      this.ctx.fillRect(borderMargin + innerSize, borderMargin, -border, innerSize);
      this.ctx.fillRect(borderMargin, borderMargin + innerSize, innerSize, -border);

      // Draw egg
      this.ctx.drawImage(
        this.sprites["eggo"],
        egg_pos.x * cellSize + borderMargin + border,
        egg_pos.y * cellSize + borderMargin + border,
        cellSize,
        cellSize
      );

      // Rita ölen mannen
      this.ctx.drawImage(
        this.sprites["ÖÖÖÖÖL"],
        beer_pos.x * cellSize + borderMargin + border,
        beer_pos.y * cellSize + borderMargin + border,
        cellSize,
        cellSize
      );

      // Draw Fnake
      for (let i = 0; i < joints.length; i++) {
        const pos = joints[i];
        if (i == 0) {
          // Draw head
          const dirName = getDirName(dir);
          this.drawPart(pos.x, pos.y, "head", dirName);
        } else if (i == joints.length - 1) {
          // Draw tail
          const prev = joints[i - 1];
          const invDiff = {
            x: prev.x - pos.x,
            y: prev.y - pos.y,
          };
          this.drawPart(pos.x, pos.y, "tail", getDirName(invDiff));
        } else {
          const prev = joints[i - 1];
          const next = joints[i + 1];
          const pos = joints[i];
          const diff = {
            x: pos.x - prev.x,
            y: pos.y - prev.y,
          };
          const dirName = getDirName(diff);

          // Determine if straight
          const diffPrevNext = {
            x: next.x - prev.x,
            y: next.y - prev.y,
          };
          const isStraight =
            (diffPrevNext.x !== 0 && diffPrevNext.y === 0) ||
            (diffPrevNext.x === 0 && diffPrevNext.y !== 0);

          if (isStraight) {
            // This is a straight
            this.drawPart(pos.x, pos.y, "straight", dirName);
          } else {
            // This is a bend
            const next = joints[i + 1];
            const diffNext = {
              x: next.x - pos.x,
              y: next.y - pos.y,
            };
            const dirNext = getDirName(diffNext);
            const bendDir = getBendDir(dirName, dirNext);
            // Draw bend
            this.drawPart(pos.x, pos.y, "bend", bendDir);
          }
        }
      }
      if (drunkLevel > 1) {
        this.drunkTransform();
      }
    },
    drunkTransform() {
      // Hela skärmen bara svajar
      const d = new Date();
      
      // Svajar åt sidorna
      for (let i = 0; i < this.canvasSize; i++) {
        let offset = Math.round(
          0.7 * drunkLevel * Math.sin(d.getTime() / 80 + i / 70)
        );

        let rowData = this.ctx.getImageData(
          borderMargin,
          borderMargin + i,
          innerSize,
          1
        );
        this.ctx.clearRect(borderMargin, borderMargin + i, innerSize, 1);
        this.ctx.putImageData(rowData, borderMargin + offset, borderMargin + i);
      }

      // SSvajar uppåt och nedåt
      let adfagew = Math.ceil(0.7 * drunkLevel);
      for (let i = -adfagew; i < this.canvasSize + adfagew; i++) {
        let offset = Math.round(
          0.4 * drunkLevel * Math.sin(d.getTime() / 100 + i / 100)
        );

        let rowData = this.ctx.getImageData(
          borderMargin + i,
          borderMargin,
          1,
          innerSize
        );
        this.ctx.clearRect(borderMargin + i, borderMargin, 1, innerSize);
        this.ctx.putImageData(rowData, borderMargin + i, borderMargin + offset);
      }
    },
    die() {
      alive = false;
      this.show_name_input = true;
      this.deathAnimation();
      document.getElementsByClassName("restart")[0].classList.remove("hidden");
      document.getElementsByClassName("restart")[0].classList.add("move");
    },
    deathAnimation() {
      deathAnimationTime++;
      this.ctx.clearRect(0, 0, this.canvasSize, this.canvasSize);
      if (deathAnimationTime % 60 < 40) {
        this.ctx.font = `bold ${(deathAnimationTime % 60) + 30}px Atari`;
        this.ctx.fillStyle = "#ffeb3b";
        this.ctx.textAlign = "center";
        this.ctx.textBaseLine = "middle";
        const canvas = document.getElementById("canvas");
        if (this.score == 1337) {
          this.ctx.fillText(
            "Du dog!",
            canvas.width / 2,
            canvas.height / 2 + 30
          );
          this.ctx.fillText(
            "ELIIIIT!!!",
            canvas.width / 2,
            canvas.height / 2 + 100
          );
        } else if (this.score < 150) {
          this.ctx.fillText(
            "Du dog!",
            canvas.width / 2,
            canvas.height / 2 + 30
          );
          this.ctx.fillText(
            "Nybörjardrickare",
            canvas.width / 2,
            canvas.height / 2 + 100
          );
        } else if (this.score > 150 && this.score < 500) {
          this.ctx.fillText(
            "Du dog!",
            canvas.width / 2,
            canvas.height / 2 + 30
          );
          this.ctx.fillText(
            "Väl drucket",
            canvas.width / 2,
            canvas.height / 2 + 100
          );
        } else if (this.score > 500 && this.score < 1000) {
          this.ctx.fillText(
            "Du dog!",
            canvas.width / 2,
            canvas.height / 2 + 30
          );
          this.ctx.fillText(
            "Sann student",
            canvas.width / 2,
            canvas.height / 2 + 100
          );
        } else if (this.score > 1000) {
          this.ctx.fillText(
            "Du dog!",
            canvas.width / 2,
            canvas.height / 2 + 30
          );
          this.ctx.fillText(
            "Kungssupare",
            canvas.width / 2,
            canvas.height / 2 + 100
          );
        }
        this.ctx.fillText(
          `Poägg: ${this.score}`,
          canvas.width / 2,
          canvas.height / 2 - 50
        );
      }
      if (!alive) {
        requestAnimationFrame(this.deathAnimation);
      } else {
        this.ctx.clearRect(0, 0, this.canvasSize, this.canvasSize);
        return;
      }
    },
    move() {
      // Flytta fram Fnake ett steg

      movedThisTick = false;
      const newPos = {
        x: joints[0].x + dir.x,
        y: joints[0].y + dir.y,
      };

      // Dö om utanför skärmen
      if (newPos.x < 0 || newPos.x > 11 || newPos.y < 0 || newPos.y > 11) {
        this.die();
        return;
      }

      // Hamnade på ägg?
      if (newPos.x == egg_pos.x && newPos.y == egg_pos.y) {
        this.score += drunkLevel;
        maxLength += 1;
        moveInterval = base_move_interval * 0.98 ** joints.length;
        this.spawnEgg();
      }

      // Hamnade på bärs?
      if (newPos.x == beer_pos.x && newPos.y == beer_pos.y) {
        drunkLevel = drunkLevel * 2;
        if (drunkLevel >= 128) {
          drunkLevel = 128;
        }
        this.spawnBeer();
      }

      // Spara nya positionen (Fnake blir längre)
      joints.splice(0, 0, newPos);

      // Om Fnake är för lång tas sista segmentet bort
      if (joints.length > maxLength) {
        joints.pop();
      }

      // Fnake får inte krocka i sig själv
      if (dir.x + dir.y != 0) {
        for (let i = 1; i < joints.length; i++) {
          if (newPos.x == joints[i].x && newPos.y == joints[i].y) {
            this.die();
            return;
          }
        }
      }
    },
    spawnEgg() {
      // Spawna nytt ägg men inte ovanpå Fnake
      let approved = false;
      let new_egg_pos;
      // Testa olika platser tills vi hittar en bra
      while (!approved) {
        new_egg_pos = {
          x: Math.floor(Math.random() * 12),
          y: Math.floor(Math.random() * 12),
        };
        // Ägget får inte vara på Fnake
        for (let i = 0; i < joints.length; i++) {
          if (
            !(new_egg_pos.x == joints[i].x && new_egg_pos.y == joints[i].y) &&
            !(new_egg_pos.x == egg_pos.x && new_egg_pos == egg_pos.y) &&
            !(new_egg_pos.x == beer_pos.x && new_egg_pos.x == beer_pos.y)
          ) {
            approved = true;
          } else {
            approved = false;
            break;
          }
        }
      }
      egg_pos.x = new_egg_pos.x;
      egg_pos.y = new_egg_pos.y;
    },
    spawnBeer() {
      // Samma som ovan men för öl
      drinkTimer = 0;
      let aproved = false;
      let new_beer_pos;
      while (!aproved) {
        new_beer_pos = {
          x: Math.floor(Math.random() * 12),
          y: Math.floor(Math.random() * 12),
        };
        // Öl får inte vara på Fnake
        for (let i = 0; i < joints.length; i++) {
          if (
            !(new_beer_pos.x == joints[i].x && new_beer_pos.y == joints[i].y) &&
            !(new_beer_pos.x == beer_pos.x && new_beer_pos == beer_pos.y) &&
            !(new_beer_pos.x == egg_pos.x && new_beer_pos.y == egg_pos.y)
          ) {
            aproved = true;
          } else {
            aproved = false;
            break;
          }
        }
      }
      beer_pos.x = new_beer_pos.x;
      beer_pos.y = new_beer_pos.y;
    },
    initTupp() {
      // Spawnar tuppen på slumpad plats
      joints[0].x = Math.floor(Math.random() * 12);
      joints[0].y = Math.floor(Math.random() * 12);
    },
    newGame() {
      // Återställ spelet
      document.getElementsByClassName("restart")[0].classList.add("hidden");
      document.getElementsByClassName("restart")[0].classList.remove("move");

      drunkLevel = 1;

      initialized = false;
      movedThisTick = false;
      moveInterval = base_move_interval;
      alive = true;
      this.score = 0;
      this.show_name_input = false;
      maxLength = 1;
      egg_pos = {
        x: 0,
        y: 0,
      };
      joints = [
        {
          x: 0,
          y: 0,
        },
      ];
      dir = {
        x: 0,
        y: 0,
      };

      this.gameLoop();
    },
    gameLoop() {
      if (!initialized) {
        this.initTupp();
        this.spawnEgg();
        this.spawnBeer();
        initialized = true;
      }
      if (!alive) return;
      moveTiming++;
      const ready = moveTiming >= moveInterval;
      if (ready) {
        drinkTimer += moveTiming;
        moveTiming = 0;
        this.move();
        this.draw();
        //Minska fullhet baserat på hur full du är, går snabbare när mer full
        if (drinkTimer >= drunkDecay / drunkLevel ** 0.65 && drunkLevel > 1) {
          drunkLevel = drunkLevel / 2;
          drinkTimer = 0;
        }
        // Uppdatera fyllometer
        document.getElementsByClassName("multiplier")[0].innerHTML =
          "x" + drunkLevel.toString();
        document.documentElement.style.cssText =
          "--drunk_percent: " + drunkLevel.toString() + "%;";
      }
      requestAnimationFrame(this.gameLoop);
    },
    loadSprites() {
      // Ladda in alla bilder som kan tänkas behövas
      const names = ["head", "straight", "bend", "tail", "eggo", "ÖÖÖÖÖL"];
      for (const sprite_name of names) {
        const img = new window.Image();
        img.src = require("@/assets/fnake/highres_sprites/" +
          sprite_name +
          ".png");
        img.onload = () => {
          this.sprites[sprite_name] = img;
        };
      }
    },
    listenForInput() {
      window.addEventListener("keydown", (e) => {
        const keyBindings = {
          // Använd piltangenterna för att styra din figur
          ArrowUp: { x: 0, y: -1 },
          ArrowLeft: { x: -1, y: 0 },
          ArrowDown: { x: 0, y: 1 },
          ArrowRight: { x: 1, y: 0 },
          // Vimknappar :)
          k: { x: 0, y: -1 },
          h: { x: -1, y: 0 },
          j: { x: 0, y: 1 },
          l: { x: 1, y: 0 },
        };

        // Välj åt vilket håll Fnake ska svänga
        const newDir = keyBindings[e.key];
        if (newDir === undefined) return;
        const ident = newDir.x == dir.x && newDir.y == dir.y;
        // Får inte vända tvärt
        const reverse =
          newDir.x !== 0 ? newDir.x == -dir.x : newDir.y == -dir.y;
        if (newDir !== undefined && !ident && !reverse && !movedThisTick) {
          movedThisTick = true;
          dir = newDir;
        }
      });
    },
    // Nedan används för kryptering
    strtoarr(str) {
      return new TextEncoder().encode(str);
    },
    arrtostr(arr) {
      return new TextDecoder().decode(arr);
    },
    salt() {
      var vector = new Uint8Array(16);
      crypto.getRandomValues(vector);
      return Array.from(vector);
    },
    encrypt(txt, pas, slt, fnc) {
      var vector = new Uint8Array(slt);
      crypto.subtle
        .digest({ name: "SHA-256" }, this.strtoarr(pas))
        .then((res) => {
          crypto.subtle
            .importKey("raw", res, { name: "AES-CBC" }, false, [
              "encrypt",
              "decrypt",
            ])
            .then((key) => {
              crypto.subtle
                .encrypt(
                  { name: "AES-CBC", iv: vector },
                  key,
                  this.strtoarr(txt)
                )
                .then((res) => {
                  fnc(Array.from(new Uint8Array(res)), Array.from(vector));
                });
            });
        });
    },
  },
  mounted() {
    const canvas = document.getElementById("canvas");
    this.ctx = canvas.getContext("2d", { willReadFrequently: true });
    this.loadSprites();
    this.listenForInput();
    this.gameLoop();
  },
};
</script>

<style>
:root {
  --drunk_percent: 10%;
}

.row.score-count {
  width: 440px;
  margin: 4px auto;
  font-size: 40px;
  color: #f1b434;
}

.move {
  animation: move 0.5s alternate 3 ease-in-out;
}

@keyframes move {
  from {
    rotate: 0deg;
  }

  to {
    rotate: 360deg;
  }
}

.restart {
  transition: ease-in;
  position: absolute;
  color: #ffffff;
  background-color: #c5942a;
  border-radius: 1px;
  padding: 0 24px;
  font-size: 50px;
  top: calc(50vh + 142px);
  left: 0;
  right: 0;
  margin: auto;
  width: max-content;
}

.hidden {
  visibility: hidden;
}

.leaderboard_message {
  line-height: 200%;
}

#name_input {
  color: white;
  margin: auto;
  background: transparent;
  border: 4px solid #fafafa;
  font-size: 24px;
  width: 152px;
  text-align: center;
}

.submit_name {
  font-size: 20px;
  margin: 8px auto;
  display: block;
}

.leaderboard_container {
  position: relative;
  margin-top: 50px;
  text-align: center;
}

.drunk_container {
  height: 450px;
  width: 50px;
  position: absolute;
  right: calc(50% - 325px);
  top: calc(50% - 175px);
}

.drunkbar {
  height: 350px;
  width: 50px;
  background-image: linear-gradient(to top,
      yellow 0% var(--drunk_percent),
      #a1a095 var(--drunk_percent) 100%);
}

.multiplier {
  font-size: 2em;
}
</style>
